import { graphql } from "gatsby"
import * as React from "react"
import * as styles from "./about-hero.css"
import {
  Box,
  ButtonList,
  Container,
  Flex,
  Kicker,
  Section,
  Subhead,
  SuperHeading,
  Text,
} from "./ui"
import {
  desktopView
} from "./hero.css"

import nftCalendarImage from './nft-calendar.png'
import moonlyLogo from './moonly-logo.png'
import sailartLogo from './sailart-logo.png'

export default function Hero(props) {
  return (
    <Section>
      <Container>
        <Flex gap={4} variant="responsive">
          <Box width="60%">
            <SuperHeading as="h1">
              {props.kicker && <Kicker>{props.kicker}</Kicker>}
              {props.h1}
            </SuperHeading>
            <Subhead as="h2">{props.subhead}</Subhead>
            <Text as="p" className={styles.aboutHeroText}>{props.text}</Text>
            <ButtonList links={props.links} />
          </Box>
          <Box width="40%" className={desktopView}>
            {props.image && (
              <img
                style={{width:`85%`, borderRadius:`20px`}}
                alt={props.image.alt}
                src={props.image.url}
              />
            )}
          </Box>
        </Flex>
        <Flex variant="responsive">
        <strong> Our partners: </strong>
        <a href="https://nftcalendar.io/event/paralleelium-series-1-eternal-garden/" target="_blank"><img src={nftCalendarImage} width="100px"/></a>
        <a href="https://moon.ly/" target="_blank"><img src={moonlyLogo} width="100px"/></a>
        <a href="https://www.sailart.io/" target="_blank"><img src={sailartLogo} width="100px"/></a>
      </Flex>
      </Container>
    </Section>
  )
}

export const query = graphql`
  fragment HomepageHeroContent on HomepageHero {
    id
    kicker
    h1: heading
    subhead
    text
    links {
      id
      href
      text
    }
    image {
      id
      gatsbyImageData
      alt
      url
    }
  }
`
