import * as React from "react"
import { graphql } from "gatsby"
import {
  Container,
  Section,
  FlexList,
  Box,
  Icon,
  Heading,
  Text,
  Space,
} from "./ui"

function Benefit(props) {
  return (
    <Box as="li" width="full" style={{textAlign:`center`}}>
      {props.image && (
        <img
          style={{maxWidth:`800px`}}
          width="100%"
          alt={props.image.alt}
          src={props.image.url}
        />
      )}
    </Box>
  )
}

export default function BenefitList(props) {
  return (
    <Section id="roadmap">
      <Container>
        <Box center>
          {props.heading && <Heading>{props.heading}</Heading>}
          {props.text && <Text variant="lead">{props.text}</Text>}
        </Box>
        <Space size={3} />
        <FlexList gutter={3} variant="start" responsive wrap>
          {props.content.map((benefit) => (
            <Benefit key={benefit.id} {...benefit} />
          ))}
        </FlexList>
      </Container>
    </Section>
  )
}

export const query = graphql`
  fragment HomepageBenefitListContent on HomepageBenefitList {
    id
    heading
    text
    content {
      id
      heading
      text
      image {
        id
        gatsbyImageData
        alt
        url
      }
    }
  }
`
